import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';
import { CustomValidatorsService } from 'src/app/services/validation/custom-validators.service';

@Component({
  selector: 'app-add-new-program',
  templateUrl: './add-new-program.component.html',
  styleUrls: ['./add-new-program.component.scss']
})
export class AddNewProgramComponent implements OnInit {
  formGroup: any
  datePickerId = new Date().toISOString().split("T")[0];
  submitted: boolean = false
  imageDetails: any = {}

  constructor(
    private fb: FormBuilder,
    private toast: ToastClientService,
    public customValidation: CustomValidatorsService,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,


  ) {
    this.formGroup = this.fb.group({
      program_name: new FormControl("", [Validators.required, this.customValidation.specialCharValidator]),
      brand_name: new FormControl("", [Validators.required, this.customValidation.specialCharValidator]),
      acc_manager_name: new FormControl("", [Validators.required, this.customValidation.specialCharValidator, Validators.pattern(/^\S*$/)]),
      start_date: new FormControl(this.datePickerId, [Validators.required, this.customValidation.specialCharValidator]),
      end_date: new FormControl("", []),
      video_link: new FormControl("https://www.youtube.com/embed/3gJtbBWvFm0", [Validators.required]),
      banner_image: new FormControl(""),
      alt_text: new FormControl("", [Validators.required]),
      program_description: new FormControl("", []),
      // id: -1,
      has_beat: true,
      has_channel: true,
      has_target: true,
      admin_panel_config: true,
      budget: new FormControl("", [Validators.required, this.customValidation.specialCharValidator]),
      create_template: false,
      hierarchy_type: ['', Validators.required]
    })
  }
  ngOnInit() {

  }

  get f() {
    return this.formGroup.controls
  }


  handleUpload(event) {
    const file = event.target.files[0];
    console.log(file)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageDetails.image = reader.result
      this.imageDetails.name = file.name
    };
  }

  onSubmit(formdata) {
    formdata['password'] = 123456
    formdata['id'] = -1
    this.imageDetails.image ? formdata.banner_image = this.imageDetails.image : formdata.banner_image = ''
    formdata.unit_id = ''
    formdata.has_beat = 0
    formdata.has_channel = 0
    formdata.has_target ? formdata.has_target = 1 : formdata.has_target = 0;
    formdata.admin_panel_config ? formdata.admin_panel_config = 1 : formdata.admin_panel_config = 0;
    formdata.create_template ? formdata.create_template = 1 : formdata.create_template = 0
    if (formdata.banner_image && formdata.banner_image.indexOf("http") == 0) {
      formdata.banner_image = '';
      return
    }

    this.submitted = true;
    if (this.formGroup.invalid) {
      return this.toast.Warning('Enter The Required Feilds')
    }
    console.log(formdata)
    //
    let apiUrl = this.apiConfig.create_update_program;
    this.apiService.post(apiUrl, formdata)
      .subscribe((res: any) => {
        this.toast.Success(res.body.data.message);
        this.apiService.$beatResonseData.emit(formdata.has_beat);
        this.formGroup.reset();
        this.submitted = false;
        this.router.navigateByUrl('signin')
      },
        (err) => {
          console.log(err)
          console.error(err)
        }
      );
  }

}