/* tslint:disable */
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  params = new HttpParams().set('is_loader', 'yes')

  //STAGING MVP
  baseUrl2 = 'https://api.hubadmin.bigcitytrade3.bigcityvoucher.co.in/v1/';

  //Murali
  // baseUrl2 = 'http://localhost/bigcitytrade_hubadmin_api_2.0/web/v1/';


  // baseUrl2 = 'http://192.168.1.223/bigcitytrade_hubadmin_api_2.0/web/v1';
  // UAT 
  // baseUrl2 = "https://uat.api.hubadmin.bigcitytrade.bigcityvoucher.co.in/v1";
  // DEMO
  // baseUrl2 = "https://hubadminapi.bigcitytrade.bigcitydays.in/v1";

  //sisa Server2
  //baseUrl2 = "https://sisa.hubadmin.api.bigcityvoucher.co.in/v1"


  //testurl 
  // baseUrl2 = 'https://api.hubadmin.bigcitytrade2.bigcityvoucher.co.in/v1'
  comMatrixURL = 'http://127.0.0.1:8020/api/comunication';

  //VEERA
  // baseUrl2 = 'http://192.168.1.175/bigcitytrade_hubadmin_api_2.0/web/v1';

  imageUrl = 'https://api.hubadmin.bigcitytrade.bigcityvoucher.co.in';


  //DashBoard
  getDashboardDetails = this.baseUrl2 + 'dashboard/dashboard-data'

  //Fulfliment 
  orderDetails = this.baseUrl2 + 'rocaclient/getordercumulative'
  getOrderLists = this.baseUrl2 + "rocaredemption/order-listing?";
  getListProgram = this.baseUrl2 + "hubadmin/list-programs";
  uploadEGVOrderStatus = this.baseUrl2 + "rocaredemption/order-offer-code-upload";
  uploadPhysiaclOrderStatus = this.baseUrl2 + "rocaredemption/order-statusupload";
  rejectDropdown = this.baseUrl2 + "rocaredemption/rejection-reason";
  approveRejectOrder = this.baseUrl2 + "rocaredemption/approve-rejectby-bcp";
  deliveryPartner = this.baseUrl2 + "rocaredemption/delivery-partner";

  downloadEgvRewards = this.baseUrl2 + "rocaredemption/egv-summary"
  downloadPhysicalRewardExcel = this.baseUrl2 + "redemption/order-summary";
  orderStatus = this.baseUrl2 + "rocaredemption/readorderstatus";
  storeStatus = this.baseUrl2 + "rocaredemption/setorderstatus";
  getVoucherCodes = this.baseUrl2 + "rocaredemption/offercode"




  // General 

  login = this.baseUrl2 + "login/login";
  logout = this.baseUrl2 + "login/logout"
  programConfig = this.baseUrl2 + "programs/get-programs-configuration?program_id=1";
  //program setup
  setupProgram = this.baseUrl2 + 'application-config/index?pwd='
  clearDataBase = this.baseUrl2 + 'application-config/delete-all'

  //enrollments
  getEnrollments = this.baseUrl2 + 'visit-tracker/get-enrollments?program_id=1';
  getReasons = this.baseUrl2 + 'visit-tracker/rejection-reasons?program_id=1';
  bulkApprove = this.baseUrl2 + 'visit-tracker/bulk-verification';
  getBeatsByFse = this.baseUrl2 + 'visit-tracker/fse-beats?supervisor_id='
  bulkApproveReject = this.baseUrl2 + 'users/bulk-approve-reject-slab'
  updateAddress = this.baseUrl2 + 'visit-tracker/update-user-address'

  getMasterDataList = this.baseUrl2 + 'user-role/select-tables'
  getValidationList = this.baseUrl2 + 'user-role/get-fieldvalidation'
  //users
  usersExcelImport = this.baseUrl2 + 'users/import-users' //cargill multi sheet approach
  userExcelUpload = this.baseUrl2 + 'users/user-import' // whitelabel single sheet approach


  //new exl import
  // userExcelUploadNew = this.baseUrl2 + 'users/import-userdetails?program_id=1';
  userExcelUploadNew = this.baseUrl2 + 'users/import-userdetails-rolewise';
  userBulkUpload = this.baseUrl2 + 'users/import-user-batch';
  getUsersBatchFiles = this.baseUrl2 + 'users/get-user-batch';

  // User-super-visor-mapping
  getUserHeirachyExcelHeaders = this.baseUrl2 + 'users/download-hierarchy-excel'
  uploadHHeirachyExcel = this.baseUrl2 + `users/import-hierarchy-details?${this.params}`
  downloadUserHierachyexcel = this.baseUrl2 + 'users/get-import-hierarchy'

  // Inactive Users
  inactiveusers = this.baseUrl2 + `users/inactivate-hierarchy-data?${this.params}`
  getHierracryUserList = this.baseUrl2 + 'users/get-hierarchy-id-details'
  inactiveHierracy = this.baseUrl2 + 'users/inactivate-hierarchy'

  // Change Hierrachy For Users
  changeUser = this.baseUrl2 + 'users/import-hierarchy-changes'

  // Change SuperVisor Heirrachy
  SupervisorSwap = this.baseUrl2 + 'users/supervisor-swap'

  // Supervisor Swap
  supervisorSplitAssign = this.baseUrl2 + 'users/supervisor-splitand-assign'


  // Entity
  entityExcelData = this.baseUrl2 + 'users/download-entity-excel';
  getEntityList = this.baseUrl2 + 'users/get-entity-data'
  importEntityExcel = this.baseUrl2 + 'users/import-entity'



  //whitelabel
  getDashBoardDetails =
    this.baseUrl2 + "adminreport/generalreports?program_id=1&";
  //***MASTERDATA***/
  // Region
  configGetRegion = this.baseUrl2 + "region/get-region?program_id=1";
  configAddRegion = this.baseUrl2 + "region/create-region";
  configUpdateRegion = this.baseUrl2 + "region/update-region";
  configDeleteRegion = this.baseUrl2 + "region/delete-region";
  importExcelRegion = this.baseUrl2 + "region/import-region";
  userPointSummary = this.baseUrl2 + 'staff/get-user-points?id='

  //states
  configGetStates = this.baseUrl2 + "state/get-state?program_id=1";
  configAddState = this.baseUrl2 + "state/create-state";
  configEditState = this.baseUrl2 + "state/update-state";
  configDeleteState = this.baseUrl2 + "state/delete-state";
  importExcelState = this.baseUrl2 + "state/import-state";
  //Cities
  configGetCities = this.baseUrl2 + "city/get-city";
  configAddCity = this.baseUrl2 + "city/create-city";
  configEditCity = this.baseUrl2 + "city/update-city";
  configDeleteCity = this.baseUrl2 + "city/delete-city";
  importExcelCity = this.baseUrl2 + "city/import-city";

  //Bannres
  bannersList = this.baseUrl2 + "banner/get-banner?program_id=1";
  createBanner = this.baseUrl2 + "banner/create-banner?";
  updateBanner = this.baseUrl2 + "banner/update-banner?";
  deleteBanner = this.baseUrl2 + "banner/delete-banner?";

  // 
  stateBasedOnRegion = this.baseUrl2 + 'loyalty-target/get-state?region_id=';
  cityBasedOnState = this.baseUrl2 + 'loyalty-target/get-city?state_code=';
  //Brands
  configGetBrands = this.baseUrl2 + "brands/get-brand";
  configAddBrand = this.baseUrl2 + "brands/create-brand";
  configEditBrand = this.baseUrl2 + "brands/update-brand";
  configDeleteBrand = this.baseUrl2 + "brands/delete-brand";
  // Languages
  configGetLanguages = this.baseUrl2 + "language/get-languages";
  configAddLanguage = this.baseUrl2 + "language/create-language";
  configEditLanguage = this.baseUrl2 + "language/update-language";
  configDeleteLanguage = this.baseUrl2 + "language/delete-language";
  // Frequency
  configGetFrequency = this.baseUrl2 + "frequency/get-frequency";
  configAddFrequency = this.baseUrl2 + "frequency/create-frequency";
  configEditFrequency = this.baseUrl2 + "frequency/update-frequency";
  // Units
  configGetUnit = this.baseUrl2 + "units/get-units";
  configAddUnit = this.baseUrl2 + "units/create-unit";
  configEditUnit = this.baseUrl2 + "units/update-unit?id=";

  //Features
  createFeatures = this.baseUrl2 + "user-role/create-features?";
  deleteFeatures = this.baseUrl2 + "user-role/delete-features?id=";

  //Dynamic Fields
  getDynamicList = this.baseUrl2 + "user-role/get-table-fields?";
  getFeaturesDropdown = this.baseUrl2 + "user-role/get-features";
  getFieldsTypeDropdown = this.baseUrl2 + "user-role/get-fieldtypes";
  getInputTypeDropdown = this.baseUrl2 + "user-role/get-inputtypes";
  createDynamicField = this.baseUrl2 + "user-role/create-table-fields";
  deleteDynamicField = this.baseUrl2 + "user-role/delete-table-fields";
  getTableClumn = this.baseUrl2 + "user-role/gettablecolumn"

  //Field Group
  getFieldGroupList = this.baseUrl2 + "user-role/get-table-fields-group";
  createFieldGroup = this.baseUrl2 + "user-role/create-table-fields-group";

  //Role Settings
  getDynamicFieldList = this.baseUrl2 + "user-role/get-table-fields-bygroup";
  getRoleHirarchy = this.baseUrl2 + "user-role/get-parent-child-roles?role_id="
  configureRole = this.baseUrl2 + "user-role/update-role"
  getRoleConfigurations = this.baseUrl2 + "user-role/get-rolefields?role_id="
  // Versions
  getVersions = this.baseUrl2 + 'version-management/get-version'
  addVersion = this.baseUrl2 + 'version-management/create-version'
  updateVersion = this.baseUrl2 + 'version-management/update-version?id='
  deleteVersion = this.baseUrl2 + 'version-management/delete-version?id='
  // Brand Categories
  configGetBrandCategory =
    this.baseUrl2 + "brand-category/get-brand-categories";
  configAddBrandCategory =
    this.baseUrl2 + "brand-category/create-brand-category";
  configUpdateBrandCategory =
    this.baseUrl2 + "brand-category/update-brand-category"; //same for delete
  //ROLES
  // OLD API
  configGetRoles = this.baseUrl2 + "user-role/get-user-roles";
  // NEW
  getRoles = this.baseUrl2 + "user-role/get-role";
  downloadUserExcel = this.baseUrl2 + "users/download-user-excel?program_id=1";

  configAddRoles = this.baseUrl2 + "user-role/create-user-role";
  configUpdateRoles = this.baseUrl2 + "user-role/update-user-role"; //same for delete

  createRole = this.baseUrl2 + "user-role/create-role?program_id=1";


  //***USER MANAGEMENT***/
  allUsers = this.baseUrl2 + "users/get-users";
  allProfiles = this.baseUrl2 + "user-role/get-user-roles";
  createUserPost = this.baseUrl2 + "users/create-user";
  updateUserPost = this.baseUrl2 + "users/update-user?id=";
  importExcelUser = this.baseUrl2 + "users/import-users";
  userRegistrationDetails = this.baseUrl2 + 'user-role/supervisors?'
  getUserById = this.baseUrl2 + "users/get-users?id=";
  getSingleUserByRole = this.baseUrl2 + "users/get-users-by-profile?";

  //User Details(Mobile) Update
  updateUserDetails = this.baseUrl2 + "hubadmin/update-user-details"
  //Filter data, regions,states.cities,channel,roles, geography
  filterDataParams = this.baseUrl2 + "course/get-course?program_id=1";
  filterStatesParams = this.baseUrl2 + "state/get-state?program_id=1";
  filterCitiesParams = this.baseUrl2 + "city/get-city?program_id=1";
  //GET OUTLETS MAPPED TO A TSE
  getMappedOutlets = this.baseUrl2 + "visit-tracker/get-tse-outlets";
  //visit tracker
  getTasksBasedOnCampaign = this.baseUrl2 + 'visit-tracker/visit-tracker-tasks?program_id=1&campaign_id=';
  getVtTseList = this.baseUrl2 + 'visit-tracker/visit-tracker-tse?program_id=1&task_id=';
  //**Channel**//
  getAllChannel = this.baseUrl2 + "channels/get-channel?program_id=1";
  //Reports
  enrollmentReports = this.baseUrl2 + 'outlet-reports/export-enrollment-users'
  twoTimeRejectedReport = this.baseUrl2 + "outlet-reports/admin-rejected-report"
  getUsersByRole = this.baseUrl2 + 'outlet-reports/userby-region'
  engagementReports = this.baseUrl2 + 'outlet-reports/engagement-report';
  regionWiseReports = this.baseUrl2 + 'outlet-reports/export-region-wise-targets';
  targetsVsAchievedReports = this.baseUrl2 + 'outlet-reports/export-target-vs-achieved';
  regionWisePoints = this.baseUrl2 + 'outlet-reports/outlet-points';
  pointBalanceReport = this.baseUrl2 + 'outlet-reports/point-balance-report';
  redemptionReport = this.baseUrl2 + 'outlet-reports/redemption-report';
  topTenReport = this.baseUrl2 + 'outlet-reports/export-target-achieved-rank';
  outletWiseInvoiceReport = this.baseUrl2 + 'outlet-reports/export-outlet-wise-invoice-report';
  categoryWiseAchivementReport = this.baseUrl2 + 'outlet-reports/export-product-category-wise-achievement';
  downloadSelectedUsersList = this.baseUrl2 + 'users/download-userdata';
  ordersReport = this.baseUrl2 + 'outlet-reports/ecommerce-order-report';
  schemeReport = this.baseUrl2 + 'outlet-reports/scheme-report';
  //***REWARDS***//

  //** Point Balance Report */

  //reward category
  getRewardCategories =
    this.baseUrl2 + "rewards/get-reward-category";
  addRewardCategories = this.baseUrl2 + "rewards/create-reward-category";
  editRewardCategories = this.baseUrl2 + "rewards/update-reward-category";
  deleteRewardCategories = this.baseUrl2 + "rewards/delete-reward-category";
  rewardHistory = this.baseUrl2 + "rewards/get-reward-orders?program_id=1";
  getRewardProducts =
    this.baseUrl2 + "rewards/get-reward-products";
  getRewardProductbyId =
    this.baseUrl2 + "rewards/get-reward-products?program_id=1&id=";
  createRewardProducts = this.baseUrl2 + "rewards/create-reward-products";
  updateRewardProducts = this.baseUrl2 + "rewards/update-reward-products";
  deleteRewardProducts = this.baseUrl2 + "rewards/delete-reward-products";
  getRewardSubCategory =
    this.baseUrl2 +
    "/rewards/get-reward-sub-categories?program_id=1&reward_category_id=";
  getRewardsBrands =
    this.baseUrl2 +
    "/rewards/get-reward-category-brands?program_id=1&reward_category_id=";

  //reward sub category
  getRewardSubCategories =
    this.baseUrl2 + "rewards/get-reward-subcategory";
  addRewardSubCategories = this.baseUrl2 + "rewards/create-reward-subcategory";
  editRewardSubCategories =
    this.baseUrl2 + "rewards/update-reward-subcategory";
  deleteRewardSubCategories =
    this.baseUrl2 + "rewards/delete-reward-subcategory";
  //Reward Type
  getRewardType = this.baseUrl2 + "reward-type/get-reward-types";
  // Engagement API
  engagementCreateActivity =
    this.baseUrl2 + "engagement/create-campaign-activity";
  engagementUpdate = this.baseUrl2 + "engagement/update-task?id=";
  getAllEngagement = this.baseUrl2 + "engagement/get-engagements";
  createEngagementTask = this.baseUrl2 + "engagement/create-task";
  getUserAssignment = this.baseUrl2 + "user-modules/get-all-whitelisted-user";
  getSchemeUsers = this.baseUrl2 + "user-modules/get-all-whitelisted-userids";
  getUsersFilter = this.baseUrl2 + "users/search-users-by-filter";
  getQuizDetails = this.baseUrl2 + "engagement/get-quiz-activities?";
  assignUserDetails = this.baseUrl2 + "engagement/assign-user-task";
  createQuizEngage = this.baseUrl2 + "engagement/create-quiz";
  updateQuizEngage = this.baseUrl2 + "engagement/update-quiz?id=";
  createpointsConfig = this.baseUrl2 + "engagement/create-engagement-point-structure";
  getEngagementTaskPoints = this.baseUrl2 + "engagement/engagement-point-structure?task_id=";
  getAssignedUsers = this.baseUrl2 + "engagement/get-user-tasks?task_id=";
  uploadImages = this.baseUrl2 + "engagement/upload-img";

  adhoc = this.baseUrl2 + "ops-panel/adhoc-userpoints";

  //***REWARDS***//
  getAllOffers = this.baseUrl2 + "ecommerce/loaddata-for-offer-details?program_id=1";
  //Schemes
  getSchemes = this.baseUrl2 + "scheme/get-scheme";
  addSchemes = this.baseUrl2 + "scheme/create-scheme-data";
  getSchemeConfig = this.baseUrl2 + "scheme/get-scheme?id=";
  getSchemeProductCategory = this.baseUrl2 + "scheme/get-brand-category";
  extendScheme = this.baseUrl2 + 'scheme/update-scheme-date?id=';
  getSchemeConfigSettings = this.baseUrl2 + '';
  updateScheme = this.baseUrl2 + 'scheme/update-scheme?id=';
  downloadSchemeUserExcel = this.baseUrl2 + 'scheme/download-users?program_id=1'

  uploadSchemeUserExcel = this.baseUrl2 + "users/intermediate-user"
  getSpinWheels = this.baseUrl2 + 'spin-wheel/list-spin-config?id='


  //schemes-maker-cheker

  generateSchemeMakerCheker = this.baseUrl2 + "scheme/scheme-maker-checker";
  uploadApprovedSchemeExcel = this.baseUrl2 + "scheme/scheme-upload-points";
  rejectSchemeUploadPoints = this.baseUrl2 + "scheme/reject-scheme-upload-points";



  //Visibility /get-posm-config
  getVisibilityList =
    this.baseUrl2 + "visibility/get-posm-elements?program_id=1";
  addVisibility = this.baseUrl2 + "visibility/create-posm-elements";
  editVisibility = this.baseUrl2 + "visibility/update-posm-elements";
  deleteVisibility = this.baseUrl2 + "visibility/delete-posm-elements";
  saveVisibilityConfig = this.baseUrl2 + "visibility/create-posm-config";
  getVisibilityConfig =
    this.baseUrl2 + "visibility/get-posm-config?program_id=1";
  updateVisibilityConfig = this.baseUrl2 + "visibility/update-posm-config";
  getVisibilitySubmittedList =
    this.baseUrl2 +
    "/visibility/get-posm-audit-transactions?page=";
  visibilityApproveOrReject =
    this.baseUrl2 + "visibility/posm-audit-approve-reject";
  getPosmOnChannel = this.baseUrl2 + 'visibility/get-channel-posm-elements?program_id=1&channel_id=';
  getShelfConditions = this.baseUrl2 + 'visibility/get-shelves-cooler-purity?program_id=1'
  getShelfConditionsForSubmission = this.baseUrl2 + 'visibility/get-shelves-points?program_id=1&posm_master_config=';
  //ecommerce
  getProductsList = this.baseUrl2 + "product/listallproducts?program_id=1";
  getProductById =
    this.baseUrl2 + "product/editproduct?program_id=1&product_id=";
  getProductDelete = this.baseUrl2 + "product/deleteproduct";
  addToProductList = this.baseUrl2 + "product/addnewproduts";
  getProductCategories =
    this.baseUrl2 + "ecommerce/get-ecommerce-categories?program_id=1";
  updateProduct = this.baseUrl2 + "product/updateproduct";
  createGeography = this.baseUrl2 + "geographical/create-geography";
  getProductVarient = this.baseUrl2 + "product/get-product-variants";
  getProductVarientValues =
    this.baseUrl2 + "product/get-product-variant-values?product_variant_id=";
  createOfferDetails = this.baseUrl2 + "ecommerce/create-offer-details";
  getOfferDetails =
    this.baseUrl2 + "ecommerce/get-offer-details?program_id=1&offer_id=";
  getEcommerceSubCategories =
    this.baseUrl2 +
    "/ecommerce/get-ecommerce-sub-categories?program_id=1&category_id=";

  /**
   * Content & learning
   */
  getContenentAndLearning =
    this.baseUrl2 + "content-learning/get-course?program_id=1&course_id=";
  getTaskTypes = this.baseUrl2 + "content-learning/course-type";
  courseCreation = this.baseUrl2 + "content-learning/create-course";
  courseUserAssign = this.baseUrl2 + "content-learning/map-course-users";
  updateContentAndLearning = this.baseUrl2 + "content-learning/update-course";
  deleteContentAndLearning = this.baseUrl2 + "content-learning/delete-course";
  createCourseQuiz = this.baseUrl2 + "content-learning/create-quiz";
  updateCourseQuiz = this.baseUrl2 + "content-learning/update-quiz";
  deleteCourseQuiz = this.baseUrl2 + "content-learning/delete-quiz";
  getAllCourseQuiz =
    this.baseUrl2 + "content-learning/get-quiz?program_id=1&course_id=";
  getContentAndLearningMappedUser =
    this.baseUrl2 + "content-learning/mapped-course-user-lists";

  /**
   * Visit tracker
   */
  newEnrollmentHeaders = this.baseUrl2 + 'users/get-userenrollment-headers'
  getVisits = this.baseUrl2 + "visit-tracker/get-visits?program_id=1";
  createVisits = this.baseUrl2 + "visit-tracker/create-visits";
  getVisitEnrollment =
    this.baseUrl2 + "visit-tracker/get-enrollments?program_id=1";
  approveRejectEnroll =
    this.baseUrl2 + "visit-tracker/approve-reject-enrollment";
  approveRejectEnrollNew =
    this.baseUrl2 + "users/approve-reject-enrollment";
  saveScheme = this.baseUrl2 + "scheme/create-scheme-data";
  createEnroll = this.baseUrl2 + "visit-tracker/create-enrollment";

  // Influncers
  getEncryptionCodes = this.baseUrl2 + 'scan-earn/get-scanner-detail';
  uploadInfluencerQR = this.baseUrl2 + 'users/upload-scanner';

  uploadBulkInfluencerQR = this.baseUrl2 + 'users/upload-influencers-qr?program_id=1';
  downloadZipFileQRCode = this.baseUrl2 + 'users/genrate-influencers-qr?program_id=1';




  /****/
  // targetUserFilter = 
  getTargetAchivementList = this.baseUrl2 + "loyalty-target/get-targets";
  updateTargetAchivement = this.baseUrl2 + "loyalty-target/update-target";
  addTargetAchivement = this.baseUrl2 + "loyalty-target/create-target";
  assignedLoyaltyTarget = this.baseUrl2 + "loyalty-target/get-loyalty-targets";
  createWhitelist = this.baseUrl2 + "user-modules/create-user-module";
  getModule = this.baseUrl2 + "module/get-modules";
  getUserModules = this.baseUrl2 + "user-modules/get-user-modules";
  createLoyalty = this.baseUrl2 + "loyalty-target/add-target-point-structure";
  getAssignedUser = this.baseUrl2 + "loyalty-target/get-assigned-users";
  getLoyaltyTargets = this.baseUrl2 + "loyalty-target/get-loyalty-targets";
  updateLoyaltyTarget = this.baseUrl2 + "loyalty-target/update-loyalty-target";
  downloadTargetFile = this.baseUrl2 + 'loyalty-target/download-target-details';
  downloadAchievementExcel = this.baseUrl2 + 'loyalty-target/download-achievement-target?program_id=1';
  /**Excel section */
  uploadMultiProducts = this.baseUrl2 + "product/upload-products";
  uploadMultiRewards = this.baseUrl2 + "rewards/create-reward-products-excel";
  createSalesAchivement = this.baseUrl2 + "loyalty-target/create-achievements";
  getSalesAchivements =
    this.baseUrl2 + "loyalty-target/get-loyalty-achievements";
  updateSalesAchievement =
    this.baseUrl2 + "loyalty-target/update-loyalty-achievement";
  approveSalesAchievement =
    this.baseUrl2 + "loyalty-target/approve-sales-achievements";
  filterByCity = this.baseUrl2 + 'city/filter-city';
  productUploadExcelFields = this.baseUrl2 + 'product/product-upload-fields';
  getReardProductsWithPagination = this.baseUrl2 + 'rewards/get-reward-products-filter?';
  getAllLoyaltyTargetsMapping = this.baseUrl2 + 'loyalty-target/get-loyalty-target-general-mapping?target_id=';
  getAllParentRolls = this.baseUrl2 + 'user-role/parentroles';
  getAllModules = this.baseUrl2 + 'user-modules/get-modules-lists';
  getRollProfileList = this.baseUrl2 + 'user-role/profiles';
  getRollTypes = this.baseUrl2 + 'user-role/roletypes';
  getTargetListBasedOnPage = this.baseUrl2 + "loyalty-target/get-loyalty-target-general-mapping?target_id=";
  getPointListBasedOnPage = this.baseUrl2 + "loyalty-target/get-loyalty-point-general-mapping?target_id=";
  createBrandTargets_general = this.baseUrl2 + "loyalty-target/create-loyalty-general-setting";
  getHierarchyLevel_roles = this.baseUrl2 + 'user-role/role-hierarchy';
  excel_upload_targetsDetails = this.baseUrl2 + 'loyalty-target/update-loyalty-targets';
  update_targets_for_sku = this.baseUrl2 + 'loyalty-target/update-loyalty-general-setting-with-products?id=';
  setTarget_points_for_sku = this.baseUrl2 + 'loyalty-target/create-loyalty-points-setting';
  createArchivements_excelUpload = this.baseUrl2 + "loyalty-target/create-achievements";
  getArchivement_list = this.baseUrl2 + 'loyalty-target/get-achievements-list?program_id=1';
  generateCheckerAndMaker = this.baseUrl2 + "loyalty-target/create-achievement-report";
  makerCheckerForScheme = this.baseUrl2 + 'scheme/schemeverification';
  downloadSchemeExcel = this.baseUrl2 + 'scheme/scheme-excel-achievement?achivement_id=';
  uploadNewSchemeMakerData = this.baseUrl2 + 'scheme/scheme-maker-checker-transactions';
  approveSchemeMakerChecker = this.baseUrl2 + 'scheme/scheme-approve-reject-achievement';
  getGeneratedCheckerAndMaker = this.baseUrl2 + "loyalty-target/get-achievement-report?program_id=1";
  getDownloadReport = this.baseUrl2 + "loyalty-target/get-excel-achievement?program_id=1&";
  approveReport = this.baseUrl2 + "loyalty-target/approve-reject-achievement";
  modifyCheckerAndMakerPoints = this.baseUrl2 + "loyalty-target/maker-checker-transactions";
  brandsExcelHeaders = this.baseUrl2 + 'brands/brand-upload-fields';
  brandsExcelUpload = this.baseUrl2 + "brands/upload-brands";
  deleteTarget = this.baseUrl2 + "loyalty-target/delete-targets";
  getTargetPointBrands = this.baseUrl2 + "brands/get-brand";
  getUserTargetPoints = this.baseUrl2 + "loyalty-target/user-targets-points?target_id=";
  getUserTargets = this.baseUrl2 + "loyalty-target/get-users-targets?target_id=";
  getUserPoints = this.baseUrl2 + "loyalty-target/get-users-points?target_id=";
  getEngementDetailsForEdit = this.baseUrl2 + "engagement/get-engagementbyid?eng_id=";
  getBrandCategoryHeaders = this.baseUrl2 + 'brand-category/excel-brand-category-headers';
  uploadExcelDataBrandCategory = this.baseUrl2 + "brand-category/upload-brand-category";
  uploadExcelForTargets = this.baseUrl2 + "loyalty-target/upload-target-points";
  downloadExcelForTargets = this.baseUrl2 + "loyalty-target/get-excel-target-sku?program_id=1";
  get_all_rewards_products = this.baseUrl2 + 'reward/productlisthome?program_id=1';



  // Staff & Agent Panel

  // All ops-panel api here ..
  getOrderList = this.baseUrl2 + "ops-panel/get-order-listings";


  // Staff & Agent Panel
  staff_Dashboard = this.baseUrl2 + "staff/team-leader-dashboard"
  staff_invoce_trasection = this.baseUrl2 + "staff/invoice-bulkassignlist";
  staff_enrollment = this.baseUrl2 + "staff/entrollement-bulkassignlist";
  staff_unassign_count = this.baseUrl2 + "staff/get-unassigned-invoice";
  engagement_unassign = this.baseUrl2 + "staff/get-unassigned-engagement";
  enrollment_unassign = this.baseUrl2 + "staff/get-unassigned-entrollement";
  agent_enrollment = this.baseUrl2 + "staff/get-enrollement-list";
  get_user_details = this.baseUrl2 + "users/get-userdetails?";

  get_user_with_kyc_details = this.baseUrl2 + "hubadmin/get-user-kyc-details"
  approve_reject_kyc = this.baseUrl2 + "hubadmin/approve-reject-user-kyc"
  get_rejected_reason = this.baseUrl2 + "users/rejection-reasons"

  getStaffEnagmentDashboard = this.baseUrl2 + "staff/team-leader-enagement-dashboard";
  getStaffAllAgentDashboard = this.baseUrl2 + "staff/pitcurebulkassignlist";
  PICTURE = this.baseUrl2 + 'staff/get-engagement-transactions';
  bulkAssignEnagement = this.baseUrl2 + 'staff/bulkassign';
  agentDashboard = this.baseUrl2 + 'staff/agent-dashboard';
  PICTURE_TEMPLATE = this.baseUrl2 + 'staff/get-picture-templates';
  PICTURE_APPROVE = this.baseUrl2 + 'staff/approvepicture';
  PICTURE_REJECT = this.baseUrl2 + 'staff/rejectpicture';
  agentInvoice = this.baseUrl2 + 'staff/get-invoice-transactions';
  agentApprovedInvoice = this.baseUrl2 + 'staff/get-approved-invoices-by-id';
  agentRejectedInvoice = this.baseUrl2 + 'staff/get-rejected-invoices-by-id';
  agentPendingInvoice = this.baseUrl2 + 'staff/get-pending-invoices-by-id';




  //tier-approval
  getTierList = this.baseUrl2 + 'tier/list-tier-for-agent';
  tierApprovalStatus = this.baseUrl2 + 'tier/agent-tier-approve-reject';


  //reset-verification
  getVerifyCountForUsers = this.baseUrl2 + 'enrollment/doc-verification-count';
  resetVerifyCount = this.baseUrl2 + 'enrollment/update-doc-verification-count';

  //account-reset-verification
  getAccVerifyCountForUsers = this.baseUrl2 + 'enrollment/acc-verification-count';
  resetAccVerifyCountForUsers = this.baseUrl2 + 'enrollment/update-acc-verification-count'


  /**
   * Communication Matrix
   */
  get_basic_communication_matrix = this.baseUrl2 + 'communication-panel/get-communication-matrix';
  get_matrix_module_details = this.baseUrl2 + 'communication-panel/get-scenario-details?';
  update_communication_matrix = this.baseUrl2 + 'communication-panel/update-scenario-details';
  send_announcement = this.baseUrl2 + "communication-panel/create-custom-notifications";
  getMatrixModulesDetails = this.baseUrl2 + "communication-panel/get-scenario-detail?";
  triggerAdhocNotification = this.baseUrl2 + "communication-panel/adhoc-push-notifications?";
  triggerEngageRemNotification = this.baseUrl2 + "communication-panel/trigger-reminder-push-notification-for-engagement?";
  triggerTargetRemNotification = this.baseUrl2 + "communication-panel/achievement-remainder-push-notifications?";
  triggerSchemeRemNotification = this.baseUrl2 + "communication-panel/schemes-remainder-push-notifications?";

  // All ops-panel api of customer report here ..
  getUsrPointLedger = this.baseUrl2 + "ops-panel/user-points-ledger";
  getDownPointLedger = this.baseUrl2 + "ops-panel/download-points-ledger";



  //delivery partner
  getdelPartnerList = this.baseUrl2 + "delivery-partner/get-partner?";
  createdelPartner = this.baseUrl2 + "delivery-partner/create-partner";
  editdelPartner = this.baseUrl2 + "delivery-partner/update-partner";
  deletedelPartner = this.baseUrl2 + "delivery-partner/delete-partner";

  //vendors
  getVendorsList = this.baseUrl2 + "users/vendor-details";

  // All ops-panel api of points passbook here
  getUsrPassbook = this.baseUrl2 + "ops-panel/points-passbook";
  getDownPassbook = this.baseUrl2 + "ops-panel/download-passbook";

  uploadRewardsCategories = this.baseUrl2 + "rewards/import-reward-category?";
  uploadRewardsSubCategories = this.baseUrl2 + "rewards/import-reward-sub-category?";
  uploadRewardBrands = this.baseUrl2 + "rewards/import-reward-brands?";
  checker_maker_bonous_points = this.baseUrl2 + 'loyalty-target/get-bonous?';
  create_communication_matrix = this.baseUrl2 + 'communication-panel/create-scenario';

  engagementUserAssignExcel = this.baseUrl2 + "engagement/assign-user-task-excel";
  contentAndLearningUserAssignExcel = this.baseUrl2 + "content-learning/assign-user-task-excel";
  get_all_the_programs = this.baseUrl2 + 'programs/get-programs?id=';
  getTargetTypeDropdown = this.baseUrl2 + 'application-config/target-type';
  create_update_program = this.baseUrl2 + 'programs/create-update-programs';
  update_program_status = this.baseUrl2 + 'programs/delete-programs';
  update_login_program = this.baseUrl2 + "login/update-program";
  my_rewards_list = this.baseUrl2 + 'scratch-cards/scratch-card-headers';
  upload_my_rewards = this.baseUrl2 + 'scratch-cards/import-scratch-card';
  create_reward_brand = this.baseUrl2 + 'rewards/create-reward-brands';
  get_reward_brand = this.baseUrl2 + 'rewards/get-reward-brands';
  update_reward_brand = this.baseUrl2 + 'rewards/update-reward-brands';
  delete_reward_brand = this.baseUrl2 + 'rewards/delete-reward-brands';


  getAllComunication = this.comMatrixURL + 'list'
  create_comm_matrix = this.comMatrixURL + 'create';
  edit_comm_matrix = this.comMatrixURL + 'edit/';
  delete_comm_matrix = this.comMatrixURL + 'delete/';

  //Added by Aarif Scan & Earn
  scanEarnListing = this.baseUrl2 + "scan-earn/get-scan-earn";
  scanEarnCreate = this.baseUrl2 + "scan-earn/create-scan-earn";
  uploadScanEarn = this.baseUrl2 + "scan-earn/import-scan-earn";

  //push notification
  pushNotification = this.baseUrl2 + "communication-panel/get-template-push-notification";
  moduleList = this.baseUrl2 + "communication-panel/get-module-list";
  moduleListForReminderNoti = this.baseUrl2 + "communication-panel/get-module-list-reminder?";
  sendTempleteNotification = this.baseUrl2 + "communication-panel/update-template-push-notification";
  subModuleItemList = this.baseUrl2 + "communication-panel/get-sub-item-list";
  getRemenderNotification = this.baseUrl2 + "communication-panel/get-reminder-push-notification-list";
  addRemenderNotification = this.baseUrl2 + "communication-panel/create-sub-item-reminder-notification-data";
  updateRemenderNotification = this.baseUrl2 + "communication-panel/update-sub-item-reminder-notification-data";
  fieldList = this.baseUrl2 + "communication-panel/get-scenario-details";
  adhocRole = this.baseUrl2 + "communication-panel/get-role-list";
  adhocNotificationList = this.baseUrl2 + "communication-panel/get-adhoc-push-notification-list";
  adhocuploadExcel = this.baseUrl2 + "communication-panel/import-excel-adhoc-notification";
  addhocuploadrolewise = this.baseUrl2 + "communication-panel/create-adhoc-push-notification-based-on-role";
  addhocFileld = this.baseUrl2 + "communication-panel/get-select-field-details";
  addhocupdateNotification = this.baseUrl2 + "communication-panel/update-adhoc-notification";
  // addhocupdateNotification = this.baseUrl2 + "communication-panel/import-excel-adhoc-notification";

  //best Display Contest list
  addContest = this.baseUrl2 + "contest/create-contest";
  getContest = this.baseUrl2 + "contest/contest-general-details?contest_type_id=";
  getRewardDetails = this.baseUrl2 + "engagement/get-reward-details";
  SaveDCC = this.baseUrl2 + "engagement/display-contest-configuration";
  getConfigurationdetails = this.baseUrl2 + "engagement/get-configuration-details?contest_id=";
  contestAssignUsers = this.baseUrl2 + "contest/map-users";
  userAssigned = this.baseUrl2 + "contest/download-user?contest_id=";

  //best Display config
  getConfigList = this.baseUrl2 + "engagement/contest-general-details?";
  getSubmissionContest = this.baseUrl2 + "engagement/get-submitted-contest?";
  getContestParam = this.baseUrl2 + "engagement/get-contest-params?";
  rateSubmission = this.baseUrl2 + "engagement/rate-submission";
  getLeadreBoardList = this.baseUrl2 + "engagement/get-leaderboard-contest?";
  publishLeadreBoard = this.baseUrl2 + "engagement/publish-leaderboard";
  contestUploadUser = this.baseUrl2 + "contest/import-user";
  getContestLeg = this.baseUrl2 + "engagement/get-contest-legs?";

  //Engagement  Poll Survey
  pollSurveyList = this.baseUrl2 + "poll-survey/get-poll-survey?campain_type=";
  createUpdatePollSurvey = this.baseUrl2 + "poll-survey/create-update-poll-survey";
  deletepollSurvey = this.baseUrl2 + "poll-survey/delete-poll-survey?";
  getPollSurveyById = this.baseUrl2 + "poll-survey/get-poll-survey-by-id?contest_id=";
  savePollSurveyPointReward = this.baseUrl2 + "engagement/poll-survey-configuration?";
  getPollSurveyPointRewardDetails = this.baseUrl2 + "engagement/get-configuration-details?contest_id=";

  //Highest Salec contest
  saveValueBased = this.baseUrl2 + "contest/sales-contest-configuration";
  savePointsRewards = this.baseUrl2 + "contest/contest-reward-config";
  getValueBasedDetails = this.baseUrl2 + "contest/get-configuration?contest_id=";
  getPointRewardsDetails = this.baseUrl2 + "contest/sales-rewards?contest_id=";
  getLeadreBoardListHSC = this.baseUrl2 + "engagement/get-leaderboard-hsc?";
  publishLeadreBoardHSC = this.baseUrl2 + "engagement/publish-leaderboard-hsc";

  //Shomphy Demo
  getBrandsInvoiceList = this.baseUrl2 + "staff/get-invoice-lists?";
  brandApprovInvoice = this.baseUrl2 + "invoice/invoice-approve";
  brandRejectInvoice = this.baseUrl2 + "invoice/invoice-reject";
  getProductList = this.baseUrl2 + "invoice/get-invoice-products?";
  runScheduler = "http://api.billsnap.bigcityvoucher.co.in:6004/api/v1/apicall";



  //jaquar Agent Api
  get_user_EnrollList = this.baseUrl2 + "users/get-userdetails";

  approveRejectEnrollment = this.baseUrl2 + "users/approve-reject-enrollment";
  rejectedReasons = this.baseUrl2 + 'users/rejection-reasons'

  pointLedger = this.baseUrl2 + "visit-tracker/get-points-ledger?program_id=1"
  checkOtp = this.baseUrl2 + "visit-tracker/search-otp?program_id=1"
  getReasonList = this.baseUrl2 + "visit-tracker/ticket-reasons?";
  getClientTicketsList = this.baseUrl2 + "visit-tracker/get-tickets?program_id=1";
  approveRejectClientTicket = this.baseUrl2 + "visit-tracker/client-approve-reject-ticket?program_id=1";
  searchBySerialCodes = this.baseUrl2 + 'visit-tracker/qr-status?program_id=1'
  transferPoints = this.baseUrl2 + 'visit-tracker/transfer-points'
  transferHistory = this.baseUrl2 + 'visit-tracker/transfered-tickets?program_id=1'



  // rejectedReasons = this.baseUrl2  



  // Behaviour-Engagement
  getBehaviourTypes = this.baseUrl2 + "behavior/getbehaviortype"
  createBehaviourEngagement = this.baseUrl2 + "behavior/createbehaviorconfig"
  createCongfiguration = this.baseUrl2 + "behavior/behaviorconfig"
  uploadUser = this.baseUrl2 + "behavior/uploaduser"
  getAllBehaviourEngagements = this.baseUrl2 + "behavior/getbehaviorconfig"



  // Spin The Wheel
  SpinWheel_Config = this.baseUrl2 + "spin-wheel/spin-config"
  segment_config_list = this.baseUrl2 + "spin-wheel/segment-config"
  SpinConfig_List = this.baseUrl2 + "spin-wheel/list-spin-config"
  spin_Upoad_excel = this.baseUrl2 + "spin-wheel/spin-structure-upload"
  downloadSpinStructure = this.baseUrl2 + "spin-wheel/download-spin-structure"
  editSegementList = this.baseUrl2 + "spin-wheel/edit-segment-config"
  rewardsList = this.baseUrl2 + 'spin-wheel/list-product'

  // settings
  getFAQs = this.baseUrl2 + "programs/get-faq"
  CreateFAQs = this.baseUrl2 + "programs/create-faq"
  updateFAQs = this.baseUrl2 + "programs/update-faq"
  deleteFAQs = this.baseUrl2 + "programs/delete-faq"
  getPrivacyPolicy = this.baseUrl2 + "programs/get-privacy-policy"
  updatePrivacyPolicy = this.baseUrl2 + "programs/update-privacy-policy"
  getTermsAndCondition = this.baseUrl2 + "programs/get-terms-condition"
  updateTermsAndCondition = this.baseUrl2 + "programs/update-terms-condition"


  //program-settings

  changeProgramSettings = this.baseUrl2 + "programs/change-program-settings";


  // Points Structure
  createPointsStructure = this.baseUrl2 + "loyalty-target/create-point-config-template"
  getPointsStructure = this.baseUrl2 + "loyalty-target/get-point-config-template"

  // New Targets and Achiements
  getTargets = this.baseUrl2 + "loyalty-target/get-target"
  CreateTargets = this.baseUrl2 + "loyalty-target/create-target"
  updateTargets = this.baseUrl2 + "loyalty-target/update-target"
  getQuater = this.baseUrl2 + "frequency/get-quarter"
  getCycle = this.baseUrl2 + "frequency/get-cycle"
  assign_Target_PointStructure = this.baseUrl2 + "loyalty-target/point-structure"
  assign_users = this.baseUrl2 + `loyalty-target/target-import?${this.params}`
  updateTarget = this.baseUrl2 + `loyalty-target/update-target-import?${this.params}`
  getTargetImports = this.baseUrl2 + "loyalty-target/get-target-import"
  getPointStructureOnID = this.baseUrl2 + "loyalty-target/get-pointstructure"
  uploadAchievements = this.baseUrl2 + `loyalty-target/acievement-import?${this.params}`
  generateMakerCheckerReport = this.baseUrl2 + "loyalty-target/maker-checker"
  generatedReportsList = this.baseUrl2 + "loyalty-target/list-maker-check"
  uploadArropvedExcel = this.baseUrl2 + "loyalty-target/upload-points"
  rejectPoints = this.baseUrl2 + "loyalty-target/reject-points"


  //Quarter

  configGetQuarter = this.baseUrl2 + "frequency/get-quarter";
  configAddQuarter = this.baseUrl2 + "frequency/create-quarter";
  configEditQuarter = this.baseUrl2 + "frequency/update-quarter";
  configDeleteQuarter = this.baseUrl2 + "frequency/delete-quarter";

  //Cycle
  configGetCycle = this.baseUrl2 + "frequency/get-cycle";
  configAddCycle = this.baseUrl2 + "frequency/create-cycle";
  configEditCycle = this.baseUrl2 + "frequency/update-cycle";
  configDeleteCycle = this.baseUrl2 + "frequency/delete-cycle";

  //QrConfiguration


  approveQrConfig = this.baseUrl2 + "qr/generateqrcodes";
  qrConfig = this.baseUrl2 + "qr/qrconfig";
  geQrConfig = this.baseUrl2 + "qr/getqrconfig";
  generateQr = this.baseUrl2 + "qr/genereate-qr";
  getQrLIst = this.baseUrl2 + "qr/getqrrequestlist";
  getImportQrList = this.baseUrl2 + "qr/get-import-qr-request-list"
  qrImportConfig = this.baseUrl2 + `qr/importqrcodes`;


  //qrSchemes
  getQrSchemesConfigList = this.baseUrl2 + "qr-schemes/get-qr-schemes-config";
  createQrSchemes = this.baseUrl2 + "qr-schemes/create-qr-schemes-config";
  uploadQrSchemeSku = this.baseUrl2 + "qr-schemes/qr-sku-upload";
  schemeStatusAction = this.baseUrl2 + "qr-schemes/scheme-status-action";
  getSingleSchemeData = this.baseUrl2 + "qr-schemes/get-single-scheme-data";
  getQrSchemesSku = this.baseUrl2 + "qr-schemes/get-qr-scheme-sku";

  // Rewards Categoreies
  getListRewardCategory = this.baseUrl2 + "hubadmin/list-product-cats"
  addRewardCategory = this.baseUrl2 + "hubadmin/add-product-cats"
  editRewardCategory = this.baseUrl2 + "hubadmin/edit-product-cats"

  // Rewards Products add

  getRewardsProductsList = this.baseUrl2 + "hubadmin/list-product"
  getCategories = this.baseUrl2 + "hubadmin/list-product-cats"
  uploadBulkProducts = this.baseUrl2 + "hubadmin/product-excel-upload"
  disableProduct = this.baseUrl2 + "hubadmin/active-deactive"
  editProduct = this.baseUrl2 + "hubadmin/edit-product"

  // Instant Voucher Upload
  uploadInstantVoucher = this.baseUrl2 + "rewards/instant-voucher-upload"
  getInstantVoucherCodes = this.baseUrl2 + "rewards/list-instant-product"

  // Ad Hoc Points
  uploadAdHocPoints = this.baseUrl2 + "hubadmin/upload-adhoc-points"
  getAdHocList = this.baseUrl2 + "hubadmin/list-adhoc-points"
  approveAdHoc = this.baseUrl2 + "hubadmin/approve-adhoc-points"
  rejectAdHoc = this.baseUrl2 + "hubadmin/reject-adhoc-points"


  // Engagement
  whiteListEngagementUser = this.baseUrl2 + "engagement/whitelist-engagement";
  engagementList = this.baseUrl2 + "engagement/list-engagement";
  viewEngagementDetail = this.baseUrl2 + "engagement/view-engagement";
  createContentAndLarning = this.baseUrl2 + "engagement/create-engagement-content";
  engagementTypes = this.baseUrl2 + "engagement/engagementtypes";
  engagementElement = this.baseUrl2 + "engagement/engagementelementtypes";
  createImageEngagement = this.baseUrl2 + "engagement/create-engagement";
  engagemetnWhitelistUser = this.baseUrl2 + "engagement/user-whitelisted";
  deactiveEnagegementUser = this.baseUrl2 + "engagement/deactivate-whitelist";
  uploadInstructionImage = this.baseUrl2 + "engagement/upload-instruction-image";
  createEngagementQuiz = this.baseUrl2 + "engagement/create-engagement-content"
  getAssigned_users = this.baseUrl2 + "engagement/user-whitelisted"
  disableEngagement = this.baseUrl2 + "engagement/disable-engagement"
  getEngagementTemplateDetail = this.baseUrl2 + "engagement/elementdata";

  getEngagementById = this.baseUrl2 + "engagement/get-engagements?id=";
  getEngagementTemplateById = this.baseUrl2 + "engagement/get-picture-template?task_id=";

  setQuizCreationCompleteForcampaign = this.baseUrl2 + "engagement/set-quiz-creation-complete-forcampaign";
  pointCalcEngagement = this.baseUrl2 + "engagement/calculate-points";



  // Agent Panel Engaments 
  beersDashboard = this.baseUrl2 + 'visibility/beer-task-details'
  spiritDashboard = this.baseUrl2 + 'visibility/spirts-task-details'
  beersOotherImageTask = this.baseUrl2 + "visibility/other-image-task-beers"
  updateBeersOtherImageTask = this.baseUrl2 + "visibility/other-image-task-verification-beers"
  getPosmBeers = this.baseUrl2 + "visibility/posm-beers"
  getBrandingBeers = this.baseUrl2 + "visibility/branding-beers"
  updateBrandingBeers = this.baseUrl2 + "visibility/branding-verification-beers"
  updateBeersPosmTask = this.baseUrl2 + "visibility/posm-verification-beers"
  chillerVisibilityBeers = this.baseUrl2 + "visibility/chiller-visibility-beers";
  updateChillarVisibilityBeers = this.baseUrl2 + "visibility/chiller-verification-beers"
  warmStackBeers = this.baseUrl2 + "visibility/warm-stock-visibility-beers"
  updateWarmStackBeers = this.baseUrl2 + "visibility/warm-stock-verification-beers"
  shelfBeers = this.baseUrl2 + "visibility/shelf-visibility-beers"
  updateShelfBeers = this.baseUrl2 + "visibility/shelf-verification-beers"
  editShelfSpirits = this.baseUrl2 + "visibility/shelf-verification-sprits"

  // Agent Panel Ticket lists
  getTicketsList = this.baseUrl2 + "visit-tracker/get-tickets?program_id=1";
  approveRejectTicket = this.baseUrl2 + "visit-tracker/approve-reject-ticket?program_id=1";

  // Agent Panel Reports 


  // Tier Module
  tierConfig = this.baseUrl2 + "tier/tier-config"
  getAllAffectedModules = this.baseUrl2 + "tier/tier-earning-module"
  allTierList = this.baseUrl2 + "tier/list-tier-config"

  tierAdhocList = this.baseUrl2 + "tier/list-tier-adhoc"
  tierUpgradeConfig = this.baseUrl2 + "tier/tier-adhoc-config"

  // Reporting Panel
  enrollmentReport = this.baseUrl2 + "report-dashboard/new-enrollment-report"
  identityReport = this.baseUrl2 + "report-dashboard/identity-verification-report"
  accountVerificationReport = this.baseUrl2 + "report-dashboard/account-verification-report"


  //qr-reports
  getQrReport = this.baseUrl2 + "report-dashboard/generate-qr-report"

  // TDS(Hubadmin)
  tds_upload = this.baseUrl2 + "tds/tds-upload"
  tds_upload_unzip = this.baseUrl2 + 'tds/tds-upload-unzip'
  get_tds_lists = this.baseUrl2 + "tds/get-tds-upload"
  get_tds_unzip_excel = this.baseUrl2 + "tds/get-tds-unzip"

  //cashback voucher codes
  uploadCashbackVoucherCodes = this.baseUrl2 + "cash-back/cashback-voucher-upolad";
  getVoucherCodeList = this.baseUrl2 + "cash-back/get-cash-voucher";

  //Instant-reward_rules
  getInstantRewardRules = this.baseUrl2 + "rewards/get-instant-reward-rules";
  instantRewardRules = this.baseUrl2 + "rewards/instant-reward-rules"

  //multilanguage
  engagementMultilang = this.baseUrl2 + "engagement/engagement-multilang";
  deleteQuestionAndOption = this.baseUrl2 + "engagement/delete-question-and-options";
  // Program settings 
  getKycCount = this.baseUrl2 + "programs/get-kyc-ve rify-count"
  updateKycCount = this.baseUrl2 + "programs/kyc-verify-count"

  // Reset Bank Details
  getUserBasedOnBankDetails = this.baseUrl2 + "hubadmin/list-bank-details"
  resetBankAccount = this.baseUrl2 + "hubadmin/reset-bank-account"

  // Deactivate Users IN User Management
  getDeactivateEndroleHeaders = this.baseUrl2 + "users/bulk-hierarchy-inactivate-excel"
  inactiveHierarchyEndrole = this.baseUrl2 + "users/bulk-hierarchy-inactivate-only"

  // Create Login For Reports
  createLoginForDashboard = this.baseUrl2 + 'dashboard/create-dashboard-user';
  getReportingDashboardUsers = this.baseUrl2 + "dashboard/get-dashboard-user";
  editDashboardDetails = this.baseUrl2 + 'dashboard/edit-dashboard-user';
  deactivateDashboradLogin = this.baseUrl2 + "dashboard/active-deactive-dashboard-user"

}
export interface ApiConfigurationInterface { }